import React, { Component } from "react";
import { Link } from "react-router-dom";
import ChildServiceList from "./ChildServiceList";
import { selectBusinessIcon } from "../utils/image"
import { getParameterByName } from "../utils/formatter";

import "../styles/service-item.css";

export default class ServiceItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            item: {},
            childExist: true
        };
    }

    componentDidMount() {
        this.setState({
            isLoaded: true,
            item: this.props.service
        });
    }

    childHandler = dataFromChild => {
        this.setState({
            childExist: dataFromChild
        });
        this.props.loaded(dataFromChild);
    };

    clickHandler = e => {
        this.props.mixpanel.track("Paso 1 completado");

        this.props.update({
            id: e.target.getAttribute("dataid"),
            type: e.target.getAttribute("datatype")
        });
    };

    update = dataFromChild => {
        this.props.update(dataFromChild);
    };

    render() {
        const { error, isLoaded, item, childExist } = this.state;

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Cargando...</div>;
        } else if (this.props.checkAllowedBusiness(getParameterByName("business")) && !this.props.BusinessData.isProManager) {
            return (childExist  ? (<div className="service-item">
            <div className={"img-container " + item.get("Type")}>
                <img
                    src={selectBusinessIcon(item, getParameterByName("business"))}
                    alt={item.get("Type")}
                />
            </div>
            <div className={"excerpt " + item.get("Type")}>
                {!childExist ? (
                    <h4 className={"title " + item.get("Type")}>{item.get("Type")}</h4>
                ) : (
                    <h4 className={"title " + item.get("Type")}>
                        <Link
                            to={{
                                pathname: "/description",
                                search: this.props.location.search,
                                state: {
                                    inner: true
                                }
                            }}
                            dataid={item.id}
                            datatype={item.get("Type")}
                            onClick={this.clickHandler}
                        >
                            {item.get("Type")}
                        </Link>
                    </h4>
                )}
                <ChildServiceList
                    checkAllowedBusiness ={this.props.checkAllowedBusiness}
                    parent={item}
                    exist={this.childHandler}
                    update={this.update}
                    mixpanel={this.props.mixpanel}
                    history={this.props.history}
                    location={this.props.location}
                />
            </div>
        </div>):null)
        } else if (getParameterByName("business") != null && getParameterByName("business") != "null" && this.props.BusinessData.isProManager) {
            return  (<div className="service-item">
            <div className={"img-container " + item.get("Type")}>
                <img
                    src={selectBusinessIcon(item, "proManager")}
                    alt={item.get("Type")}
                />
            </div>
            <div className={"excerpt " + item.get("Type")}>
                    <h4 className={"title " + item.get("Type")}>
                        <Link
                            to={{
                                pathname: "/description",
                                search: this.props.location.search,
                                state: {
                                    inner: true
                                }
                            }}
                            dataid={item.id}
                            datatype={item.get("Type")}
                            onClick={this.clickHandler}
                        >
                            {item.get("Type")}
                        </Link>
                    </h4>
                <ChildServiceList
                    checkAllowedBusiness ={this.props.checkAllowedBusiness}
                    BusinessData={this.props.BusinessData}
                    parent={item}
                    exist={this.childHandler}
                    update={this.update}
                    mixpanel={this.props.mixpanel}
                    history={this.props.history}
                    location={this.props.location}
                />
            </div>
        </div>
    )
        } else {
            return (
                <div className="service-item">
                    <div className="img-container">
                        <img
                            src={selectBusinessIcon(item, getParameterByName("business"))}
                            alt={item.get("Type")}
                        />
                    </div>
                    <div className="excerpt">
                        {!childExist ? (
                            <h4 className="title">{item.get("Type")} </h4>
                        ) : (
                            <h4 className="title">
                                <Link
                                    to={{
                                        pathname: "/description",
                                        search: this.props.location.search,
                                        state: {
                                            inner: true
                                        }
                                    }}
                                    dataid={item.id}
                                    datatype={item.get("Type")}
                                    onClick={this.clickHandler}
                                >
                                    {item.get("Type")}
                                </Link>
                            </h4>
                        )}
                        <ChildServiceList
                            checkAllowedBusiness ={this.props.checkAllowedBusiness}
                            parent={item}
                            exist={this.childHandler}
                            update={this.update}
                            mixpanel={this.props.mixpanel}
                            history={this.props.history}
                            location={this.props.location}
                        />
                    </div>
                </div>
            )
        }
    }
}
