import React, { useState } from "react";
import { optimizeImage, uploadImage } from "../utils/image.js";
import Parse from "parse";
import Jobin from "jobin-client";
import { getParameterByName } from "../utils/formatter.js";
import {selectBusinessColor} from "../utils/image"
import { PulseLoader } from "react-spinners";

import imgSecure from "../assets/pagoseguro-stripe.png";



export default function StripePaymentModal(props) {
    const [loading, setLoading] = useState(false)
    const generateDraftService= ()=>{
        setLoading(true)
        let imgUrlPromise = [];
        if (props.jobInfo.picture.files.length > 0) {
            imgUrlPromise = props.jobInfo.picture.files.map(file => {
                const base64Promise = optimizeImage(file);
                return base64Promise.then(
                    base64 => {
                        // randomly generate a new file name
                        const fileName =
                            "upload-request-" +
                            Math.floor(Math.random() * 90000) +
                            10000 +
                            ".jpg";
                        return uploadImage(fileName, base64).catch(() => {
                            return undefined;
                        });
                    },
                    () => {
                        return undefined;
                    }
                );
            });
        }

        const businessId = getParameterByName("business");
        const businessPromise = businessId ? new Parse.Query("Business").get(businessId) : Promise.resolve(undefined);

        const createJobPromise = Promise.all([
            Promise.all(imgUrlPromise),
            Parse.User.currentAsync(),
            businessPromise
        ]).then(async ([imgUrl, user, business]) => {
           const fixed = await Jobin.Job.createFixedJob(
                    user.id,
                    props.jobInfo.category.categoryId,
                props.jobInfo.category.categoryType,
                props.jobInfo.category.subCategoryId !== "" ? props.jobInfo.category.subCategoryId : undefined,
                props.jobInfo.locate.center.lat,
                props.jobInfo.locate.center.lng,
                null,
               props.jobInfo.description.message,
                //props.jobInfo.availability.dateTo ? `fecha elegida por el cliente: ${props.jobInfo.availability.dateTo.format('DD/MM/YYYY')}  ${props.jobInfo.description.message}`:props.jobInfo.description.message,
                imgUrlPromise,
                'fixed',
                "invoice",
                'web',
                true,
                3,
                props.jobInfo.locate.address,
                null,
                "draft",
                business ? business.id : undefined,
                props.jobInfo.dateTo,
                null,
                props.jobInfo.multiplier
                );
            // props.jobType(null);
            // props.jobAvailability({ dateTo: null });
            // props.jobDescription({ message: null})
            return fixed;
        });

        createJobPromise.then(
            (res) => {
                console.log(res)
                window.location.replace(res.result.paymentLink);
            },
            err => {
                console.log('ERROR',err);
                // checks if the error is caused by no jobers or if its just an internal error
                
            }
        );
       
    };
    return (
        <div className="modal jobinPro">
            <h4 className="title">{props.title}</h4>
            <p className="excerpt">{props.excerpt1}</p>
            <p className="excerpt">{props.excerpt2}</p>
            <p className="excerpt link-conditions">{props.excerpt3} <a href={props.linkConditions} className="c-black" target="_blank">{props.textConditions}.</a></p>
            <img className="img-secure" src={imgSecure}/>
            <div className="one-row-button">
                <button
                    type="button"
                    style={ {backgroundColor: selectBusinessColor(getParameterByName("business")), color:  (getParameterByName("business") == "lMyRIzN79G" || getParameterByName("business") == "ZF5WZAbvQ6" || getParameterByName("business") == "kqJtDU5D9b") ? "white": "black"}}
                    onClick={() => {generateDraftService()}}>
                      {loading ? (
                            <PulseLoader
                                margin={"4px"}
                                sizeUnit={"px"}
                                size={10}
                                color={"#fff"}
                                loading={loading}
                            />
                        ) : (
                            props.button
                        )}
                </button>
            </div>
        </div>
    );
}
