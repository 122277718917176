import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByLatLng,
    getLatLng
} from "react-places-autocomplete";

import Nav from "../components/Nav";
import StepControlls from "../components/StepControlls";
import BreadCrumb from "../components/BreadCrumb";
import { connect } from 'react-redux';
import Jobin from 'jobin-client';
import "../styles/service-location.css";
import { jobPrice } from '../store/actions/jobAction';
import { jobAddress } from '../store/actions/serviceAction';

import mapsMarker from "../assets/maps-marker.svg";
import mapsMarkerGreen from "../assets/maps-marker-green.svg";
import mapsMarkerPelayo from "../assets/maps-marker-pelayo.svg";
import mapsMarkerRed from "../assets/summary-publish.svg";
import {getParameterByName} from "../utils/formatter";

let _map = null;
let _maps = null;
let _area = null;

const searchOptions = {
    types: ["address"],
    componentRestrictions: { country: ["es", "pt", "fr"] }
};

const Marker = () => (
    <div className="maps-marker">
        <img src={getParameterByName("business") == "ZF5WZAbvQ6" ? mapsMarkerRed : getParameterByName("business") == "kqJtDU5D9b" ? mapsMarkerGreen: getParameterByName("business") == "Uo1ncXDbA2" ? mapsMarkerPelayo: mapsMarker} alt="marker" />
    </div>
);

class ServiceLocation extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            mapsKey: this.props.mapsKey,
            center: this.props.center,
            zoom: this.props.zoom,
            address: this.props.address,
            addressSelected: this.props.addressSelected,
            error: this.props.error,
            disabledBtn: this.props.disabledBtn,
            nextPage: null
        };
    }

    checkLocation = () => {
        if (navigator.geolocation)
            navigator.geolocation.getCurrentPosition(this.updateByGeoLoc);
    };

    updateByGeoLoc = position => {
        geocodeByLatLng({
            lat: position.coords.latitude,
            lng: position.coords.longitude
        }).then(results => {
            if (this._isMounted) {
                this.setState({
                    center: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    },
                    zoom: 16,
                    address: results[0].formatted_address,
                    addressSelected: true,
                    disabledBtn: false
                });
            }
            // draw circle area
            if (_area) _area.setMap(null);
            if (_maps) {
                _area = new _maps.Circle({
                    strokeColor: "#202020",
                    strokeOpacity: 1,
                    strokeWeight: 1,
                    fillOpacity: 0,
                    map: _map,
                    center: this.state.center,
                    radius: 450
                });
                _area.setMap(_map);
            }
        });
    };

    handleChange = address => {
        this.setState({ address });
        this.setState({
            addressSelected: false,
            disabledBtn: true
        });
        if (_area) _area.setMap(null);
    };

    handleSelect = address => {
        const latLngPromise = geocodeByAddress(address).then(results => {
            // update address state and then return for further computations
            if (this._isMounted) {
                this.setState({
                    address: results[0].formatted_address,
                    addressSelected: true,
                    disabledBtn: false
                });
            }
            return getLatLng(results[0]);
        });
        latLngPromise
            .then(latLng => {
                if (this._isMounted) {
                    this.setState({
                        center: {
                            lat: latLng.lat,
                            lng: latLng.lng
                        },
                        zoom: 16
                    });
                }
                // draw circle area
                if (_area) _area.setMap(null);
                if (_maps) {
                    _area = new _maps.Circle({
                        strokeColor: "#202020",
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        fillOpacity: 0,
                        map: _map,
                        center: this.state.center,
                        radius: 450
                    });
                    _area.setMap(_map);
                }
            })
            .catch(error => console.error("Error", error));
    };

    apiIsLoaded = (map, maps) => {
        _map = map;
        _maps = maps;
    };

    update = async (childData) => {
        this.props.mixpanel.track("Paso 5 completado");
        this.props.jobAddress(this.state.address)
        // push state to parent to save the content
        this.props.update(childData);
        const { status, result: guild } = await Jobin.Guild.get(this.props.subCategory || this.props.job.category.categoryId);
        const fixedPrice = guild && guild.get("Price") ? guild.get("Price").fixed : false;
        let priceAmount = null;

        if(fixedPrice){
            const subPostalCode = 28;
            if(fixedPrice[subPostalCode]){
                let data = {
                    amount: fixedPrice[subPostalCode].user
                }
                this.props.jobPrice(data);
                priceAmount = fixedPrice[subPostalCode].user;
            } else if (fixedPrice.default) {
                let data = {
                    amount: fixedPrice.default.user
                }
                this.props.jobPrice(data);
                priceAmount = fixedPrice.default.user;
            } else {
                let data = {
                    amount: null
                }
                this.props.jobPrice(data);
                priceAmount = null;
            }
        }
    };

    componentDidMount() {
        this.setState({
            nextPage: 'price'
        })
        if (
            !(this.props.location.state && "inner" in this.props.location.state)
        )
        Jobin.Client.logOut().then(result => {
        });

        this._isMounted = true;

        if (this.props.address === "") this.checkLocation();
    }

    componentDidUpdate(){
        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { mapsKey, center, zoom, address, addressSelected } = this.state;
        return (
            <div id="service-location" className="section">
                <Nav />
                <div className="margin-top-72px">
                    <BreadCrumb
                        history={this.props.history}
                        location={this.props.location}
                    />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h4 className="title">
                                    ¿Dónde necesitas el servicio?
                                </h4>
                                <p className="hidden-md hidden-sm hidden-xs">
                                    Escribe la zona aproximada. Los
                                    profesionales no sabrán tu dirección exacta
                                    hasta que la compartas.
                                </p>
                                <p className="hidden-lg">
                                    Indica la zona aproximada. El profesional no
                                    sabrá tu ubicación exacta.
                                </p>
                                <div className="content">
                                    <PlacesAutocomplete
                                        value={address}
                                        onChange={this.handleChange}
                                        onSelect={this.handleSelect}
                                        searchOptions={searchOptions}
                                    >
                                        {({
                                            getInputProps,
                                            suggestions,
                                            getSuggestionItemProps,
                                            loading
                                        }) => (
                                            <div>
                                                <div className="input-search-container">
                                                    <input
                                                        {...getInputProps({
                                                            placeholder:
                                                                "Escribe la dirección aproximada",
                                                            className:
                                                                "jobin-input-search location-search-input",
                                                            type: "search"
                                                        })}
                                                    />
                                                    <button>
                                                        <svg
                                                            width="20px"
                                                            height="20px"
                                                            viewBox="0 0 20 20"
                                                            className="search-icon"
                                                        >
                                                            <defs>
                                                                <path
                                                                    d="M2.5,8.33333333 C2.5,5.11166667 5.11166667,2.5 8.33333333,2.5 C11.555,2.5 14.1666667,5.11166667 14.1666667,8.33333333 C14.1666667,11.555 11.555,14.1666667 8.33333333,14.1666667 C5.11166667,14.1666667 2.5,11.555 2.5,8.33333333 M18.9225,17.7441667 L14.19,13.0116667 C15.2158333,11.7283333 15.8333333,10.1041667 15.8333333,8.33333333 C15.8333333,4.19083333 12.4758333,0.833333333 8.33333333,0.833333333 C4.19083333,0.833333333 0.833333333,4.19083333 0.833333333,8.33333333 C0.833333333,12.4758333 4.19083333,15.8333333 8.33333333,15.8333333 C10.1041667,15.8333333 11.7283333,15.2158333 13.0116667,14.19 L17.7441667,18.9225 C18.07,19.2483333 18.5966667,19.2483333 18.9225,18.9225 C19.2483333,18.5966667 19.2483333,18.07 18.9225,17.7441667"
                                                                    id="path-1"
                                                                />
                                                            </defs>
                                                            <g
                                                                stroke="none"
                                                                strokeWidth="1"
                                                                fill="none"
                                                                fillRule="evenodd"
                                                            >
                                                                <g transform="translate(-16.000000, -8.000000)">
                                                                    <g
                                                                        id="Icono-/-Buscar"
                                                                        transform="translate(16.000000, 8.000000)"
                                                                    >
                                                                        <mask
                                                                            id="mask-2"
                                                                            fill="white"
                                                                        >
                                                                            <use xlinkHref="#path-1" />
                                                                        </mask>
                                                                        <g id="Mask" />
                                                                        <g
                                                                            id="color-mask"
                                                                            mask="url(#mask-2)"
                                                                            fill="#b0b0b0"
                                                                            fillRule="evenodd"
                                                                        >
                                                                            <rect
                                                                                id="Rectangle-13"
                                                                                x="0"
                                                                                y="0"
                                                                                width="20"
                                                                                height="20"
                                                                            />
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div
                                                    className={
                                                        suggestions.length > 0
                                                            ? "autocomplete-dropdown-container ready"
                                                            : "autocomplete-dropdown-container"
                                                    }
                                                >
                                                    {loading && (
                                                        <div>Cargando...</div>
                                                    )}
                                                    {suggestions.map(
                                                        suggestion => {
                                                            const className = suggestion.active
                                                                ? "suggestion-item active"
                                                                : "suggestion-item";
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(
                                                                        suggestion,
                                                                        {
                                                                            className
                                                                        }
                                                                    )}
                                                                >
                                                                    <span>
                                                                        {
                                                                            suggestion.description
                                                                        }
                                                                    </span>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </PlacesAutocomplete>
                                </div>
                            </div>
                            <div className="col-md-offset-1 col-md-4">
                                <div className="maps">
                                    <GoogleMapReact
                                        bootstrapURLKeys={{
                                            key: mapsKey
                                        }}
                                        center={center}
                                        zoom={zoom}
                                        yesIWantToUseGoogleMapApiInternals
                                        onGoogleApiLoaded={({ map, maps }) =>
                                            this.apiIsLoaded(map, maps)
                                        }
                                    >
                                        {addressSelected && (
                                            <Marker
                                                lat={center.lat}
                                                lng={center.lng}
                                            />
                                        )}
                                    </GoogleMapReact>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.nextPage 
                    ?
                    <StepControlls
                        history={this.props.history}
                        location={this.props.location}
                        page={this.props.step}
                        disabled={this.state.disabledBtn}
                        next={this.state.nextPage}
                        nextTrigger={!addressSelected}
                        nextTitle="Siguiente"
                        prev="picture"
                        cState={this.state}
                        update={this.update}
                    />
                    : 
                    null
                }
                
                <img
                    src="https://pixel.mathtag.com/event/img?mt_id=1443638&amp;mt_adid=230174&amp;mt_exem=&amp;mt_excl=&amp;v1=&amp;v2=&amp;v3=&amp;s1=&amp;s2=&amp;s3="
                    width="1"
                    height="1"
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isMutua: state.businessCodeReducer.isMutua,
        subCategory: state.jobReducer.category.subCategoryId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        jobPrice: (data) => dispatch(jobPrice(data)),
        jobAddress: (address) => dispatch(jobAddress(address))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceLocation);
